.container {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  
}

.terminal {
  border-radius: 25px !important;
}
